import { PropsWithChildren } from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';

import CloseIcon from '@/ui/assets/CloseIcon';
import Icon from '@/ui/assets/Icon';
import ErrorImage from '@/ui/assets/ErrorImage';

import { useScreenSize, ScreenSize } from '@/ui/hooks/useScreenSize';

interface PropsContentModal {
  /** Propiedad que muestra el modal */
  showModal?: boolean;

  /**Contenido interno del componente */
  content?: JSX.Element | null;
  /** Función que ejecuta el modal */
  buttons?: JSX.Element | null;

  /** Clase del mensaje */
  classMessage?: string;

  modalSize?: 'sm' | 'lg' | 'xl';

  classContent?: string;

  contentColClass?: number;

  onClose?: () => void;

  isHalfModal?: boolean;

  imageSrc?: string;

  imageAlt?: string;

  title?: string;

  description?: string;

  phoneNumber?: string;

  email?: string;
}

/**
 * Modal que muestra contenido o imágenes para confirmar o notificar la ejecución de una acción
 * @component
 */
/**
 * Componente `ContentModal` que representa un modal con contenido personalizado.
 *
 * @param {boolean} showModal - Indica si el modal debe mostrarse.
 * @param {React.ReactNode} buttons - Botones opcionales para mostrar en el modal.
 * @param {React.ReactNode} content - Contenido principal del modal.
 * @param {string} classMessage - Clase CSS para el mensaje del modal.
 * @param {string} modalSize - Tamaño del modal (por ejemplo, 'lg', 'sm').
 * @param {string} classContent - Clase CSS para el contenido del modal.
 * @param {string} contentColClass - Clase CSS para la columna de contenido.
 * @param {boolean} isHalfModal - Indica si el modal es de tamaño medio.
 * @param {() => void} onClose - Función que se ejecuta al cerrar el modal.
 * @param {string} imageSrc - Fuente de la imagen a mostrar en el modal.
 * @param {string} imageAlt - Texto alternativo para la imagen.
 * @param {string} title - Título del modal.
 * @param {string} description - Descripción del modal.
 * @param {string} phoneNumber - Número de teléfono a mostrar en el modal.
 * @param {string} email - Correo electrónico a mostrar en el modal.
 *
 * @returns {JSX.Element} Elemento JSX que representa el modal.
 */
/** */
const ContentModal = ({
  showModal,
  buttons,
  content,
  classMessage,
  modalSize,
  classContent,
  contentColClass,
  isHalfModal,
  onClose,
  imageSrc,
  imageAlt,
  title,
  description,
  phoneNumber,
  email,
}: PropsWithChildren<PropsContentModal>): JSX.Element => {
  /** Hook que contiene las dimensiones de la pantalla y maneja los componentes de acuerdo al resultado obtenido  */
  const { width, maxLargeWidth }: ScreenSize = useScreenSize();

  /** Variable que define si es entorno mobile o desktop */
  const isMobile: boolean = width < maxLargeWidth;

  return (
    <>
      {isHalfModal ? (
        <>
          {isMobile ? (
            <Modal
              show={showModal}
              onHide={onClose}
              dialogClassName='modal-dialog-centered'
              contentClassName='rounded-4 mh-400-px d-flex justify-content-between shadow border-0 '
              size={modalSize}>
              <Row>
                <Col lg={6}>
                  <Col xs={12}>
                    <Image
                      className='rounded-top-4'
                      fluid
                      src={imageSrc}
                      alt={imageAlt}
                      onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                        e.currentTarget.src = ErrorImage;
                      }}
                    />
                  </Col>
                </Col>
                <Col lg={6}>
                  <Col xs={12} className='text-end top-0 pe-2 py-3 d-flex justify-content-end '>
                    <CloseIcon classMain='btn-icon-light' onClick={onClose} />
                  </Col>
                  <Col lg={12} className='d-flex justify-content-center'>
                    <Col lg={9} className='d-flex flex-column '>
                      <span className='text-primary-3 h2 text-center'>{title}</span>
                      <span className='text-justify px-3 text-secondary-2 p-regular pb-3'>{description}</span>
                      <span className='text-center pb-3'>
                        <Icon name='ico-whatsapp' classMain='pe-3'></Icon>
                        <span className='text-primary-3'>{phoneNumber}</span>
                      </span>
                      <span className='text-center mb-4'>
                        <Icon name='ico-email' classMain='pe-3 '></Icon>
                        <span className='text-primary-3'>{email}</span>
                      </span>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Modal>
          ) : (
            <Modal
              show={showModal}
              onHide={onClose}
              dialogClassName='modal-dialog-centered'
              contentClassName='rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
              size={modalSize}>
              <Row>
                <Col lg={6}>
                  <Col xs={12}>
                    <Image
                      className='rounded-start'
                      fluid
                      src={imageSrc}
                      alt={imageAlt}
                      onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                        e.currentTarget.src = ErrorImage;
                      }}
                    />
                  </Col>
                </Col>
                <Col lg={6}>
                  <Col xs={12} className='text-end top-0 pe-2 pt-2 d-flex justify-content-end min-vh-25'>
                    <CloseIcon classMain='btn-icon-light' onClick={onClose} />
                  </Col>
                  <Col lg={12} className='d-flex justify-content-center'>
                    <Col lg={9} className='d-flex flex-column '>
                      <span className='text-primary-3 h1 text-center'>{title}</span>
                      <span className='text-justify px-3 text-secondary-2 p-large'>{description}</span>
                      <span className='text-center pt-5'>
                        <Icon name='ico-whatsapp' classMain='pe-3'></Icon>
                        <span className='text-primary-3'>{phoneNumber}</span>
                      </span>
                      <span className='text-center pt-4'>
                        <Icon name='ico-email' classMain='pe-3'></Icon>
                        <span className='text-primary-3'>{email}</span>
                      </span>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Modal>
          )}
        </>
      ) : (
        <Modal
          show={showModal}
          onHide={onClose}
          dialogClassName='modal-dialog-centered'
          contentClassName=' rounded-4 mh-400-px d-flex justify-content-between shadow border-0'
          size={modalSize}>
          <Col xs={12} className='text-end pe-2 pt-2 d-flex justify-content-end'>
            <CloseIcon classMain='btn-icon-light' onClick={onClose} />
          </Col>
          <Col xs={contentColClass} className='mx-auto'>
            <Col xs={12} className={classMessage}>
              {title}
            </Col>
            <Col xs={12} className={classContent}>
              {content}
            </Col>
            {buttons && (
              <Col xs={12} className='mx-auto mt-3'>
                {buttons}
              </Col>
            )}
          </Col>
          <Col xs={12} className='align-self-end pt-2'>
            <div className={'bg-primary-3 pt-2 rounded-4 rounded-top-0 '} />
          </Col>
        </Modal>
      )}
    </>
  );
};

export default ContentModal;
